var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Edit Procurement Category", "has-last-modified": false, "is-fetch-entity-by-id": false, "has-deactivate-button": false } }, [_c("text-input", { key: "gPortfolio", attrs: { "label": "Procurement Category", "placeholder": "Type Procurement Category", "rules": "required", "form-item": "", "span": 12 } }), _c("select-input", { key: "gpGroupId", attrs: { "label": "Portfolio", "reference": "ppv.common.ppv-portfolio-group", "placeholder": "Select Global Portfolio Group", "source": "gpGroupId", "source-label": "gpGroupName", "rules": "required", "span": 10, "form-item": "" } })], 1);
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditGlobalPortfolios",
  inject: ["crud"],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      GPortfolio: "",
      GPGroupId: ""
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    }
  },
  mounted() {
    this.visible = true;
  },
  created() {
    this.storeValue("UpdateType", "update");
  },
  methods: {
    storeValue(resourceKey, value) {
      this.editForm = __spreadProps(__spreadValues({}, this.editForm), {
        [resourceKey]: value
      });
      this.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditGlobalPortfolios = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "ppv.common.ppv-portfolio-group", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "ppv.global-portfolios", "api-url": _vm.apiUrl, "redirect-route": "/ppv-actuals/global-portfolios" } }, [_c("edit-global-portfolios")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditGlobalPortfolios
  },
  data() {
    return {
      EditGlobalPortfolios,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
